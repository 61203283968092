<template>
  <div>
    <b-row>
      <b-col lg="6" md="6" sm="6" class="heading-title">
        <h4>View Vendors</h4>
        <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
          <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
          <b-breadcrumb-item active>View Vendors</b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
      <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
    </b-row>
    <br />
      <b-card class="mb-4 w-100">
      <div class="venue-heading">
        <b-row>
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <div class="w-100 p-2">
               <multiselect
                v-model="vendor_name_selected"
                :options="vendor_name_option"
                placeholder="Select vendor name"
              >
              </multiselect>
            </div>
          </b-col>
          <b-col
            class="d-flex align-items-stretch "
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <div class="w-100 p-2">
              <div>
                  <multiselect
                  v-model="pincode_selected"
                  :options="pincode_option"
                  placeholder="Select Pincode"
                >
                </multiselect>
              </div>
            </div>
          </b-col>
          
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="6"
            md="12"
            sm="12"
          >
            <div class="w-100 d-flex justify-content-end">
              <div class="p-2">
                <b-button variant="primary" @click="applyFilter()">
                  Apply</b-button
                >
              </div>
              <div class="p-2">
                <b-button variant="primary" @click="resetFilter()">
                  Reset</b-button
                >
              </div>
              <div class="p-2">
                <b-button
                  id="button_width"
                  @click="ExportVendor()"
                  variant="primary"
                >
                  <i class="mdi mdi-export"></i>Export</b-button
                >
              </div>
              <div class="p-2" v-if="roles_id == '200ab7fb-c577-11ee-a2f6-525484f79033'">
                 <!-- <div v-if="roles_id == '86476c72-4fb5-4b1c-926d-132c0c37abc8'"> -->
     <!--  <div v-if="roles_id == '1136bab7-cd78-4902-af14-f10c6a545050'"> -->
                <!-- <b-button
                  id="button_width1"
                  variant="primary"
                  v-b-modal.RegisterVendorModal
                >
                  <i class="mdi mdi-plus"></i>Register Vendor
                </b-button> -->
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-row> 
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="7"
            md="12"
            sm="12"
          >
            <div
              class="
                w-100
                justify-content-sm-center
                justify-content-xl-start
                justify-content-md-start
              "
            >
              <div class="p-2 venue-flex">
                <b-form-select
                style="max-width: 90px;"
                  id="select_perpage"
                  v-model="pageSize"
                  :options="options"
                  @change="handlePageSizeChange($event)"
                ></b-form-select>
                <div class="venue-heading">
                  <multiselect
                  v-model="location_selected"
                  :options="location_option"
                  placeholder="Select Location"
                >
                </multiselect>
              </div>
              </div>
            </div>
          </b-col>
           
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="5"
            md="12"
            sm="12"
          >
            <div class="w-100 p-2">
              <b-form-input
                v-model="search"
                id="search"
                type="text"
                placeholder="Search here"
                v-on:keyup="key_search()"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
      </div>
      <br />
      <div>
        <b-skeleton-table v-if="load_vendors"
  :rows="10"
  :columns="7"
  :table-props="{ bordered: true, striped: true }"
></b-skeleton-table>
        <b-table v-else
          :busy="load"
          :fields="fields"
          :items="vendors"
          hover
          bordered
          show-empty
          empty-text="No Data Available"
          class="text-center"
          small
        >
        <template #table-busy>
            <b-skeleton-table
              v-if="load"
              :rows="15"
              :columns="9"
              :hide-header="true"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </template>


      <template  #cell(vendor_id)="data">

        <span class="p-2" v-if="roles_id == '200ab7fb-c577-11ee-a2f6-525484f79033'">
							<a href="javascript:void(0)"  @click="$router.push('/vendor_details/'+data.item.id)">{{ data.item.vendor_id }}</a>
        </span>
        <span v-else>
          {{ data.item.vendor_id }}
        </span>

						</template>
          <template #cell(venue_publish)="data">
            <b-form-checkbox
              name="check-button"
              switch
              v-on:change="statusChange(data.item.id)"
              :checked="data.item.venue_publish == 1"
            >
            </b-form-checkbox>
          </template>
         <!--  <template #cell(venue)="data">
            <router-link :to="'/Manage_vendors/parent_venue/' + data.item.id">
              <i class="fas fa-eye fa-lg text-primary"></i>
            </router-link>
          </template> -->
          <template #cell(venue)="data">
            <router-link :to="'/Manage_vendors/vendor_parent/' + data.item.id">
              <i class="fas fa-eye fa-lg text-primary"></i>
            </router-link>
&nbsp;
<span class="p-2" v-if="roles_id == '200ab7fb-c577-11ee-a2f6-525484f79033'">
            <router-link :to="'/vendor_details/' + data.item.id">
              <i class="fas fa-user fa-lg text-dark"></i>
          
            </router-link>
            </span>

            <i class="fas fa-solid fa-lock fa-lg cursor-pointer" @click="reset_password(data.item.vendor_id,data.item.email)" ></i>
          </template>
          <!--<template #cell(view)="data">
            <router-link :to="'/vendor_details/' + data.item.id">
              <i class="fas fa-user fa-lg text-dark"></i>
            </router-link>
          </template>-->
        </b-table>
        <b-row align-v="center">
          <b-col cols="4">
            <span
              >Showing <b>{{ totalRows }}</b> out of
              <b>{{ totalData }}</b> entries.</span
            >
          </b-col>
          <b-col cols="8">
            <b-pagination
              v-model="page"
              :total-rows="count"
              :per-page="pageSize"
              @change="handlePageChange"
              prev-text="Prev"
              next-text="Next"
              align="right"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal id="modal-center" centered title="Reset Password" ref="modal-5" hide-footer>
      <b-form >
      <b-form-group
        id="input-group-1"
        label="Password:"
        label-for="input-1"
      >
        <b-form-input
          id="password"
          :state="validatePasswordState('password')"
          v-model="password.password"
          type="password"
          placeholder="Enter Reset password"
          
        ></b-form-input>
        <b-form-invalid-feedback>
            <span v-if="!this.$v.password.password.required">
            Please enter new password</span>
            <span v-if="this.$v.password.password.required &&
            !this.$v.password.password.minLength
            ">Password must be 8 characters long.</span>
            <span v-if="this.$v.password.password.required &&
            this.$v.password.password.minLength &&
            !this.$v.password.password.passwordCheck
            ">Password should contain at least a-z or A-Z and number and
            special character..</span>
          </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="input-group-2" label="Confirm Password:" label-for="input-2">
        <b-form-input
          id="input-2"
          :state="validatePasswordState('cpassword')"
          v-model="password.cpassword"
          placeholder="Enter Confirm Password"
          type="password"
          
        ></b-form-input>
        <b-form-invalid-feedback>
            <span v-if="!this.$v.password.cpassword.required">
              Please confirm new password.
            </span>
            <span v-if="this.$v.password.cpassword.required && !this.$v.password.cpassword.sameAsPassword">
              The confirm password must be same as password.
            </span>
          </b-form-invalid-feedback>
      </b-form-group>

      <b-button type="submit" variant="primary"  @click="onSubmit">Submit</b-button> &nbsp;
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
    </b-modal>

    <b-modal
      :no-close-on-backdrop="true"
      @ok="handleOk"
      @cancel="close"
      id="RegisterVendorModal"
      hide-header
      size="lg"
      ok-title="Register"
    >
      <center>
        <h4><b>VENDOR REGISTRATION</b></h4>
      </center>
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="fname"
                  >First Name<span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="fname"
                  :state="validateState('first_name')"
                  v-model="vendorForm.first_name"
                  type="text"
                  placeholder="Enter first name"
                  aria-describedby="first_name-1-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="first_name-1-live-feedback">
                  <span v-if="!this.$v.vendorForm.first_name.required">
                    Please enter first name.</span
                  >
                  <span
                    v-if="
                      this.$v.vendorForm.first_name.required &&
                      !this.$v.vendorForm.first_name.sameAsPassword
                    "
                    >The first name must be in alphabet.</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="fname"
                  >Last Name<span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="lname"
                  :state="validateState('last_name')"
                  v-model="vendorForm.last_name"
                  type="text"
                  placeholder="Enter last name"
                  aria-describedby="last_name-1-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="last_name-1-live-feedback">
                  <span v-if="!this.$v.vendorForm.last_name.required">
                    Please enter last name.</span
                  >
                  <span
                    v-if="
                      this.$v.vendorForm.last_name.required &&
                      !this.$v.vendorForm.last_name.sameAsPassword
                    "
                    >The last name must be in alphabet.</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="email"
                  >Email<span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="email"
                  :state="validateState('email')"
                  v-model="vendorForm.email"
                  type="email"
                  aria-describedby="email-1-live-feedback"
                  placeholder="Enter email"
                >
                </b-form-input>
                <b-form-invalid-feedback id="email-1-live-feedback">
                  <span v-if="!this.$v.vendorForm.email.required">
                    Please enter email.</span
                  >
                  <span
                    v-if="
                      this.$v.vendorForm.email.required &&
                      !this.$v.vendorForm.email.email
                    "
                    >Please enter valid email.</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="conact"
                  >Phone number<span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="contact_no"
                  :state="validateState('contact')"
                  v-model="vendorForm.contact"
                  type="text"
                  placeholder="Enter phone number"
                  aria-describedby="contact-1-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="contact-1-live-feedback">
                  <span v-if="!this.$v.vendorForm.contact.required">
                    Please enter phone number.</span
                  >
                  <span
                    v-if="
                      this.$v.vendorForm.contact.required &&
                      !this.$v.vendorForm.contact.numeric
                    "
                    >The phone number number must be numeric.</span
                  >
                  <span
                    v-if="
                      (this.$v.vendorForm.contact.required &&
                        this.$v.vendorForm.contact.numeric &&
                        !this.$v.vendorForm.contact.minLength) ||
                      !this.$v.vendorForm.contact.maxLength
                    "
                    >The phone number number must be in 10 digit.</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col cols="6">
          <b-row class="py-3 align-items-center">
            <b-col cols="4"><label class="fw-medium mb-0" for="venue_name">Venue Name :</label></b-col>
            <b-col cols="7">
              <b-form-input name="venue_name" type="text" placeholder="Enter   venue name"></b-form-input>
            </b-col>
          </b-row>
        </b-col> -->
          <!-- <b-col cols="12">
            <b-row class="py-3 align-items-center">
              <b-col cols="2"
                ><label class="fw-medium mb-0" for="address"
                  >Address :</label
                ></b-col
              >
              <b-col cols="10">
                <b-form-textarea
                  placeholder="Enter address"
                  v-model="vendorForm.address"
                  rows="2"
                  max-rows="2"
                  style="width: 604px"
                >
                </b-form-textarea>
              </b-col>
            </b-row>
          </b-col> -->
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="companyName"
                  >Company Name<span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="company_name"
                  aria-describedby="company_name-1-live-feedback"
                  :state="validateState('company_name')"
                  v-model="vendorForm.company_name"
                  type="text"
                  placeholder="Enter company name"
                ></b-form-input>
                <b-form-invalid-feedback id="company_name-1-live-feedback">
                  Please enter company name.
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>
         <!--  <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="city">City :</label></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="city"
                  type="text"
                  aria-describedby="city-1-live-feedback"
                  v-model="vendorForm.city"
                  placeholder="Enter city"
                >
                </b-form-input>
              </b-col>
            </b-row>
          </b-col> -->
        </b-row>
        <b-row>
         <!--  <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="state"
                  >State :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="state"
                  type="text"
                  aria-describedby="state-1-live-feedback"
                  v-model="vendorForm.state"
                  placeholder="Enter state"
                >
                </b-form-input>
              </b-col>
            </b-row>
          </b-col> -->
          <b-col cols="6">
           <!--  <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="city"
                  >Pincode :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="pincode"
                  type="text"
                  v-model="vendorForm.pincode"
                  placeholder="Enter pincode number"
                  aria-describedby="pincode-1-live-feedback"
                  :state="validateState('pincode')"
                ></b-form-input>
                <b-form-invalid-feedback id="pincode-1-live-feedback">
                  <span v-if="!this.$v.vendorForm.pincode.numeric"
                    >The pincode number must be numeric.</span
                  >
                  <span
                    v-if="
                      (this.$v.vendorForm.pincode.numeric &&
                        !this.$v.vendorForm.pincode.minLength) ||
                      !this.$v.vendorForm.pincode.maxLength
                    "
                    >The pincode number must be in 6 digit.</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row> -->
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="gst/tin"
                  >GST/TIN :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="gst"
                  v-model="vendorForm.gst_tin"
                  type="text"
                  placeholder="Enter GST/TIN"
                  :state="validateState('gst_tin')"
                >
                </b-form-input>
                <b-form-invalid-feedback>
                  Please enter valid GST/TIN no.
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col> -->
         <!--  <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="pan">PAN :</label></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="pan"
                  v-model="vendorForm.pan"
                  type="text"
                  placeholder="Enter PAN No."
                  :state="validateState('pan')"
                >
                </b-form-input>
                <b-form-invalid-feedback id="company_name-1-live-feedback">
                  Please enter valid pan no.
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col> -->
        </b-row>
        <b-row>
          <!-- {{this.$v.vendorForm.confirm_password}} -->
         <!--  <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="password"
                  >Password<span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="password"
                  v-model="vendorForm.password"
                  :state="validateState('password')"
                  type="password"
                  placeholder="Create password"
                  aria-describedby="password-1-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="password-1-live-feedback">
                  <span v-if="!this.$v.vendorForm.password.required">
                    Please create password.</span
                  >
                  <span
                    v-if="
                      this.$v.vendorForm.password.required &&
                      !this.$v.vendorForm.password.minLength
                    "
                    >Password must be 8 characters long.</span
                  >
                  <span
                    v-if="
                      this.$v.vendorForm.password.required &&
                      this.$v.vendorForm.password.minLength &&
                      !this.$v.vendorForm.password.passwordCheck
                    "
                    >Password should contain at least a-z or A-Z and number and
                    special character..</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="confirmPassword"
                  >Confirm Password<span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="confirm_password"
                  :state="validateState('confirm_password')"
                  v-model="vendorForm.confirm_password"
                  type="password"
                  placeholder="Confirm password"
                  aria-describedby="confirm_password-1-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="confirm_password-1-live-feedback"
                  ><span v-if="!this.$v.vendorForm.confirm_password.required"
                    >Please confirm password.</span
                  >
                  <span
                    v-if="
                      this.$v.vendorForm.confirm_password.required &&
                      !this.$v.vendorForm.confirm_password.sameAsPassword
                    "
                    >The confirm password must be same as password.</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col> -->
        </b-row>

        <h6 class="custom-text mt-4"><span>Contract Details</span></h6>
        <b-row>
          <b-col md="6">
            <b-row class="mb-4">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="companyName"
                  > Contruct date<span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="company_name"
                  aria-describedby="company_name-1-live-feedback"
                  v-model="vendorForm.contruct_date"
                  type="date"
                  placeholder="Enter Subscribe Amount"
                  :state="validateState('contruct_date')"
                ></b-form-input>
                <b-form-invalid-feedback id="company_name-1-live-feedback">
                  Please Select Contruct Date.
                </b-form-invalid-feedback>
              </b-col>
					</b-row>

              
							<!-- 		<b-form-group id="phone"  >
								<b-form-checkbox v-model="biling_status" name="check-button"   > Percentage
								</b-form-checkbox> 
								
								
							</b-form-group> 

              <b-form-group id="phone"  >
								<b-form-checkbox v-model="biling_status" name="check-button"   > Subscription
								</b-form-checkbox> 
								
								
							</b-form-group> --> 

              <b-form-group label="Select Your plan" >
      <b-form-radio-group
        id="radio-group-2"
        v-model="biling_status"
        name="radio-sub-component"
      >
        <b-form-radio value="percentage">Percentage</b-form-radio>
        <b-form-radio value="subs">Subscription</b-form-radio>
      </b-form-radio-group>
    </b-form-group>
									

          </b-col>
          <b-col md="6">
            <b-row class="ml-3 mr-3 mb-4" v-if="biling_status=='subs'">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="companyName"
                  > Amount<span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="company_name"
                  aria-describedby="company_name-1-live-feedback"
                  v-model="vendorForm.subsamount"
                  type="text"
                  placeholder="Enter Subscribe Amount"
                ></b-form-input>
              
              </b-col>
					</b-row>
					
					<b-row class="ml-3 mr-3 mb-4" v-if="biling_status=='percentage'">
						<b-col cols="12" md="12">
							<table class="table">
								
								<tr v-for="(ranges,r) in vb_pencentage_list" :key="r">
									<td><i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(ranges.range_from)) }}  - <i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(ranges.range_to))}} </td>
									<td>{{ ranges.percentage }}%</td>
								</tr>
							</table>
						</b-col>
					</b-row>
          </b-col>
        </b-row>

      </b-form>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import {
  required,
  sameAs,
 // alpha,
  email,
  numeric,
  minLength,
  maxLength,
  helpers
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import helpers_ from "../../mixins/helpers";
 const passwordCheck = helpers.regex(
  "passwordCheck",
  /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!@&$#%]).*$/
);/*
const panCardNumberCheck = helpers.regex(
  "panCardNumberCheck",
  /([A-Z]){5}([0-9]){4}([A-Z]){1}$/
);
const gstNumberCheck = helpers.regex(
  "gstNumberCheck",
  /[0-9]{2}([A-Z]){5}([0-9]){4}([A-Z]){1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
); */
export default {
  name: "managevendors",
  mixins: [validationMixin,helpers_],
  data: () => ({
    title: "managevendors",
    roles_id: JSON.parse(localStorage.getItem("loggedUserData")).role_id,
    num: 1,
    load_vendors: true,
    vendorForm: {
      first_name: "",
      last_name: "",
      email: "",
      contact: "",
      address: "",
      company_name: "",
      city: "",
      state: "",
      pincode: "",
      gst_tin: "",
      pan: "",
      password: "",
      confirm_password: "",
      subsamount: "",
      contruct_date: "",
    },
    password:
    {
      password:'',
      cpassword:'',
    },
    vendor_id:'',
    vendor_email:'',
    // Month Table
    fields: [
      //  'index',
      {
        key: "vendor_id",
        label: "Vendor Id",
        sortable: true,
      }
      ,{
        key: "company_name",
        label: "Company Name",
        sortable: true,
      },
      {
        key: "vendor_name",
        label: "Vendor Name",
        sortable: true,
      },
      {
        key: "contact_no",
        label: "Phone No.",
        sortable: true,
      },
      {
        key: "email",
        label: "Email",
        sortable: true,
      },{
        key: "Created_by",
        label: "Created By",
        sortable: true,
      },
    /*   {
        key: "pincode",
        label: "Pincode",
        sortable: true,
      },
      {
        key: "location",
        label: "Location",
        sortable: true,
      },
      {
        key: "registered_by",
        label: "Registered By",
        sortable: false,
      }, */
      {
        key: "venue",
        label: "Action",
        sortable: false,
      },
      /* {
        key: "view",
        label: "View",
        sortable: false,
      }, */
    ],
    biling_status: false,
    vb_pencentage_list: [],
    item: [],
    value: "",

    options: [15, 20, 30, 50],
    vendors: [],
    pageSize: 15,
    show: false,
    load: true,
    filter: "",
    search: "",
    CurrentPage: 1,
    sortBy: "created_at",

    page: 1,
    count: 0,
    totalData: "",
    totalRows: "",

    PerPage: "50",
    pincode_selected: "",
    vendor_name_selected: "",
    location_selected: "",

    pincode_option: [],
    vendor_name_option: [],
    location_option: [],
  }),
  validations: {
    vendorForm: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      contruct_date: { required },
      contact: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
     // password: { required, minLength: minLength(8), passwordCheck },
     // confirm_password: { required, sameAsPassword: sameAs("password") },
      company_name: { required },
     // pan: { panCardNumberCheck },
     // gst_tin: {gstNumberCheck},
     // pincode: { numeric, minLength: minLength(6), maxLength: maxLength(6) },
    },
    password:
    {
      password: { required, minLength: minLength(8), passwordCheck },
      cpassword: { required, sameAsPassword: sameAs("password") },
    }
  },
  components: { Multiselect },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.vendorForm[name];
      return $dirty ? !$error : null;
    },
    
    validatePasswordState(name) {
      const { $dirty, $error } = this.$v.password[name];
      return $dirty ? !$error : null;
    },

    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.$v.vendorForm.$touch();
      if (this.$v.vendorForm.$anyError) {
        return;
      } else {
        this.handleSubmit();
      }
    },

    close() {
      this.$nextTick(() => {
        this.$bvModal.hide("RegisterVendorModal");
        this.vendorForm = {
          first_name: "",
          last_name: "",
          email: "",
          contact: "",
          address: "",
          company_name: "",
          city: "",
          state: "",
          pincode: "",
          gst_tin: "",
          pan: "",
          password: "",
          confirm_password: "",
        };
        this.$v.$reset();
      });
    },
    handleSubmit() {
      this.$v.vendorForm.$touch();
      if (!this.$v.vendorForm.$anyError) {
        axios
          .post("/admin/register_vendor", {
            first_name: this.vendorForm.first_name,
            last_name: this.vendorForm.last_name,
            email: this.vendorForm.email,
            phone: this.vendorForm.contact,
            password: this.vendorForm.password,
            password_confirm: this.vendorForm.confirm_password,
            address: this.vendorForm.address,
            gst_no: this.vendorForm.gst_tin,
            pan_no: this.vendorForm.pan,
            company: this.vendorForm.company_name,
            city: this.vendorForm.city,
            state: this.vendorForm.state,
            biling_status: this.biling_status,
            subsamount: this.vendorForm.subsamount,
            contruct_date: this.vendorForm.contruct_date,
          })
          .then((resp) => {
            if (resp.data.status) {
              this.close();
              this.vendorListing();
              this.$root.$refs.app.showToast("success", resp.data.message);
            } else {
              this.$root.$refs.app.showToast("danger", resp.data.message);
            }
          });
      }
    },
    getRequestParams(page, pageSize, sortBy = "venue_name") {
      let params = {};
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      params["sortBy"] = sortBy;
      return params;
    },
    filterListing() {
      axios.get("/admin/vendor_pincode_city_listing").then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            this.pincode_option = resp.data.vendor_pincodes;
            this.location_option = resp.data.vendor_cities;
            this.vendor_name_option = resp.data.vendor_names;
          }
        }
      });
    },
    resetFilter() {
      (this.pincode_selected = ""),
        (this.vendor_name_selected = ""),
        (this.location_selected = ""),
        this.vendorListing();
    },
    vendorListing() {
      this.load = true;
      var keyword = this.search;
      const params = this.getRequestParams(
        this.page,
        this.pageSize,
        this.sortBy
      );
      axios
        .post("/admin/get_vendors", {
          params,
          search_keyword: keyword,
          pincode: this.pincode_selected,
          city: this.location_selected,
          vendor_name: this.vendor_name_selected,
        })
        .then((resp) => {
          if (resp.data.status_code == 200) {
            this.load_vendors=false;
            this.vendors = [];
            if (resp.data.status) {
              var $createdBy = "";
              this.vb_pencentage_list =   resp.data.vb_pencentage_list;
              this.count = this.totalData = resp.data.total_records;
              this.totalRows = resp.data.vendor_details.length;
              for (var i = 0; i < resp.data.vendor_details.length; i++) {
                if (resp.data.vendor_details[i].created_by_roles == null) {
                  $createdBy = "Self";
                } else {
                    if(resp.data.vendor_details[i].created_by_roles.role.role_type === 'admin')
                    {
                       $createdBy = 'Admin';
                    }
                }
                $createdBy = $createdBy.charAt(0).toUpperCase() + $createdBy.slice(1);
                this.vendors.push({
                  id: resp.data.vendor_details[i].user_id,
                  vendor_name:
                    resp.data.vendor_details[i].first_name +
                    " " +
                    resp.data.vendor_details[i].last_name,
                  company_name: resp.data.vendor_details[i].company_name,
                  vendor_id: resp.data.vendor_details[i].vendor_auto_id,
                  contact_no: resp.data.vendor_details[i].phone,
                  email: resp.data.vendor_details[i].email,
                  pincode: resp.data.vendor_details[i].pincode,
                  location: resp.data.vendor_details[i].city,
                  registered_by: $createdBy,
                  Created_by:resp.data.vendor_details[i].createdby,
                });
              }
              this.show = false;
              this.load = false;
            } else {
              this.count = this.totalData = resp.data.total_records;
              this.totalRows = resp.data.vendor_details.length;
              this.show = false;
              this.load = false;
            }
          }
        });
      this.show = false;
      this.load = false;
    },
    handlePageChange(value) {
      this.page = value;
      this.load = true;
      this.vendorListing();
    },
    applyFilter() {
      const value = "1";
      this.handlePageChange(value);
    },
    key_search() {
      const value = "1";
      this.handlePageChange(value);
    },
    ExportVendor() {
      var keyword = this.search;
      const params = this.getRequestParams(
        this.page,
        this.pageSize,
        this.sortBy
      );
      axios
        .post(
          "/api/export_vendor_pdf",
          {
            params,
            search_keyword: keyword,
            pincode: this.pincode_selected,
            city: this.location_selected,
            vendor_name: this.vendor_name_selected,
          },
          {
            responseType: "blob",
          }
        )
        .then((resp) => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "vendor_list.pdf");
          document.body.appendChild(link);
          link.click();
        });
    },
    reset_password(vendor_id,email)
    {
      this.vendor_id = vendor_id;
      this.vendor_email = email;
      this.$refs['modal-5'].show();
    },
    onSubmit(event)
    {
      event.preventDefault();
      this.$v.password.$touch();
      if (!this.$v.password.$anyError) 
      {
        axios.post("/admin/password_reset",
        {
          password: this.password.password,
          vendor_id : this.vendor_id,
          email : this.vendor_email,
        }).then(() => {
          this.$root.$refs.app.showToast("success",'Password Reseted Successfull');
          this.$refs['modal-5'].hide();
          this.password.password= '';
          this.password.cpassword= '';
        });
      }
     
    }
    
  },

  mounted() {
    this.show = true;
    this.vendorListing();
    this.filterListing();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style scoped>


#search {
  max-width: 285px;
}

#select_perpage {
  max-width: 25%;
}
label.fw-medium {
    font-size: 13px;
}
.cursor-pointer
{
  cursor: pointer;
}
</style>